.layout-wrapper {
  display: grid;
  grid-template-columns: 400px 1fr;
  grid-template-rows: auto 1fr;
  grid-template-areas: "header header" "requirements terms";
  background-color: #f7f7f7;
  min-height: 100vh;
  padding: 0 48px;
  gap: 24px;
}
