.search-container,
.search-bar-container {
  position: relative;
}

.search-icon {
  position: absolute;
  top: 13px;
  left: 11px;
  color: rgba(0, 0, 0, 0.65);
}

.search-bar {
  width: 520px;
  padding: 0;
  padding-left: 40px;
  font-size: 18px;
  line-height: 42px;
  border: 2px solid #535353;
  border-radius: 20px;
  outline: none;
  transition: border 0.2s ease;
}
.search-bar:focus {
  border: 2px solid #cccccc;
}

.search-results-container {
  margin-top: 12px;
  border: 2px solid #eaeaea;
  border-radius: 20px;
  width: 520px;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
  position: absolute;
  top: 36px;
  background-color: white;
  max-height: 50vh;
}
.search-results-container ul {
  margin: 0;
  padding-left: 0;
}
