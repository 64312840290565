.searchSuggest {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px;
}

.searchText {
  text-align: center;
  display: block;
  margin-top: 10px;
  font-size: 16px;
  color: var(--c-text-strong)
}

.searchQuery {
  display: block;
  margin: auto;
  border-radius: 10px;
  border-color: var(--c-border);
  padding: 5px;
  padding-left: 30px;
  margin-bottom: 20px;
  font-size: 16px;
  width: 355px;
}

.filter {
  display: block;
  text-align: left;
  background-color: var(--c-card);
  color: var(--c-text-strong);
  border-color: var(--c-border);
  border-radius: 10px;
  width: 355px;
}

.filter:hover {
  background-color: var(--c-card);
  border-color: var(--c-border);
  color: var(--c-text-strong);
}

.dropdownMenu {
  height: 200px;
  overflow: scroll;
}

.courseBtn {
  display: block;
  border: 0;
  background-color: inherit;
  border-bottom: 2px solid var(--c-border);
  width: 100%;
  text-align: left;
  cursor: pointer;
}

.courseInfo {
  display: inline-block;
  width: 90%;
}

.courseNum {
  display: inline-block;
  background-color: var(--c-border);
  padding: 4px;
  border-radius: 4px;
  width: 30%;
}

.courseTitle {
  display: inline-block;
  color: var(--c-text-strong);
  width: 60%;
  padding-left: 10px;
  vertical-align: middle;
}

.credits {
  display: inline-block;
  text-align: right;
  margin: auto;
  color: var(--c-text-weak);
  width: 10%;
}

.req {
  color: var(--c-text-weak);
}

.plusIcon {
  margin-top: 15px;
  vertical-align: top;
  margin-right: 3px;
}