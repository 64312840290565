.course-block {
  border-top: 2px solid #eaeaea;
  padding: 6px 18px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.requirement-placeholder {
  background-color: var(--c-bg-yellow);
  padding: 16px 18px;
  gap: 8px;
}

.course-block-inner {
  width: 100%;
}

.course-block-inner.unlocked {
  width: 100%;
  margin-left: 8px;
}

.course-details {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.course-number {
  padding: 2px 4px;
  font-size: 0.7em;
  color: var(--c-text-green);
  background-color: var(--c-bg-green);
  margin-right: 6px;
}
.course-number.scheduled {
  background-color: rgba(54, 84, 192, 0.1);
  color: #3654c0;
}

.course-title {
  font-family: Roboto;
  font-size: 1em;
  letter-spacing: 0.25px;
  flex-grow: 1;
}

.credit-hours {
  font-family: Roboto;
  font-size: 0.9em;
  letter-spacing: 0.25px;
}

.course-requirement-fulfilled {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: Roboto;
  font-size: 0.8em;
  font-style: normal;
  font-weight: 400;
  line-height: 9px;
  letter-spacing: 0.25px;
  text-align: left;
  margin: 0;
}

.requirement-description {
  margin: 6px 6px;
}
