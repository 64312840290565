.reqList {
  overflow-y: auto;
  height: 350px;
  padding-bottom: 50px;
}

.addReqBtn {
  display: inline-grid;
  grid-auto-flow: column;
  width: 100%;
  border: 0;
  background-color: var(--c-card);
  border-bottom: 1px solid var(--c-border);
  cursor: pointer;
  padding-top: 7px;
  padding-bottom: 7px;
}

.middle {
  display: inline;
  justify-self: left;
}

.right {
  display: inline;
  justify-self: right;
}
