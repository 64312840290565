.term-wrapper {
  border: 2px solid #eaeaea;
  border-radius: 4px;
  background-color: white;
  height: fit-content;
}

.term-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  padding: 12px 18px;
}

.term-title {
  flex-grow: 1;
  margin: 0;
  font-size: 20px;
  font-weight: 400;
}

.term-credit-hours {
  margin: 0;
  font-size: 14px;
}

.term-footer {
  overflow: hidden;
  border-radius: 0 0 4px 4px;
}

.term-add-button {
  width: 100%;
  border: none;
  font-size: 1rem;
  text-transform: uppercase;
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0.1rem;
  color: var(--c-text-scarlet);
  background-color: var(--c-bg-scarlet);
  padding: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
}
