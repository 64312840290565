.search-result {
  padding: 12px 16px;
  border-top: 2px solid #eaeaea;
}
.search-result:first-of-type {
  border-top: none;
}

.search-result-listing {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

.search-result-confirmation {
  display: flex;
  flex-direction: column;
}

.search-result-confirmation p {
  margin: 0;
}

.cta-container {
  display: flex;
  flex-direction: row;
  margin-top: 8px;
}

.cta-container select {
  flex-grow: 1;
  cursor: pointer;
}

.cta-buttons {
  margin-left: 8px;
  cursor: pointer;
}

.search-result-add {
  margin-right: 8px;
}

.search-result_course-number {
  padding: 4px 8px;
  margin-right: 8px;
  background-color: #eaeaea;
  white-space: nowrap;
  font-size: 13px;
}
.search-result-listing.completed .search-result_course-number {
  background-color: rgba(62, 175, 63, 0.1);
  color: #3eaf3f;
}
.search-result-listing.scheduled .search-result_course-number {
  background-color: rgba(54, 84, 192, 0.1);
  color: #3654c0;
}

.search-result_course-title {
  flex-grow: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.search-result-course-term {
  white-space: nowrap;
  margin: 0;
  margin-left: 8px;
}

.course-req-fulfilled {
  display: block;
}

.meets-requirement {
  display: inline;
  font-size: 16px;
  font-size: 0.8em;
}