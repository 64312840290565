.wizard {
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: 475px;
  width: 662px;
  background-color: white;
  display: inline-block;
  border-radius: 8px;
  overflow: hidden;
}

.wizardSection {
  width: 330px;
  height: 475px;
  margin: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: top;
}

.divider {
  width: 1px;
  height: 475px;
  display: inline-block;
  background-color: #DDDDDD;
}

.wizardBtn {
  width: 100%;
  height: 100%;
  display: block;
  outline: none;
  border: none;
  background-color: white;
  padding: 0;
}

.wizardBtn:hover {
  cursor: pointer;
}

.wizardIconCtn {
  background-color: #B7D8FF;
  width: 84px;
  height: 84px;
  border-radius: 100px;
  display: block;
  text-align: center;
  margin: auto;
  color: #104686;
}

.wizardIcon {
  margin-top: 17px;
}

.wizardText {
  display: block;
  margin: auto;
  margin-top: 20px;
  width: 282px;
  text-align: center;
  font-size: 16px;
  color: var(--c-text-strong);
}
