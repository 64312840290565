.requirements-list {
  border: 2px solid #eaeaea;
  border-radius: 4px;
  overflow: hidden;
}

.requirements-list-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  padding: 12px 18px;
}

.requirements-list-header.remaining {
  background-color: rgba(208, 0, 0, 0.1);
}
.requirements-list-header.remaining svg {
  fill: #d00000;
}

.requirements-list-header.scheduled {
  background-color: rgba(54, 84, 192, 0.1);
}
.requirements-list-header.scheduled svg {
  fill: #3654c0;
}

.requirements-list-header.completed {
  background-color: rgba(62, 175, 63, 0.1);
}
.requirements-list-header.completed svg {
  fill: #3eaf3f;
}

.requirements-list-header h2 {
  margin: 0;
  font-size: 22px;
}
