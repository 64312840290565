.pageOverlay {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: var(--c-overlay);
}

.selectBox {
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 20px;
  height: 435px;
  width: 400px;
  background-color: var(--c-card);
  display: inline-block;
  border-radius: 8px;
  overflow: hidden;
}

.wizardH2 {
  font-size: 16px;
  color: var(--c-text-strong);
}

.wizardH3 {
  font-size: 10px;
  text-transform: uppercase;
  color: var(--c-text-weak);
}

.wizardDesc {
  font-size: 10px;
  font-style: italic;
  color: var(--c-text-weak);
}

.close-button {
  position: absolute;
  right: 1px;
  top: 1px;
}

.close-button:hover {
  cursor: pointer;
}