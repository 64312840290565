.requirement {
  padding: 6px 18px;
  border-top: 2px solid #eaeaea;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.title-hours-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.associated-course {
  font-size: 0.8em;
  color: #666;
  display: flex;
  gap: 4px;
  align-items: center;
}
