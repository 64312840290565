:root {
  --c-text-scarlet: #d00000;
  --c-bg-scarlet: #d000001a;
  --c-text-green: #3eaf3f;
  --c-bg-green: #3eaf3f1a;
  --c-text-blue: #3654c0;
  --c-bg-blue: #3654c01a;
  --c-text-yellow: #da9c24;
  --c-bg-yellow: #da9c241a;
  --c-background: #f7f7f7;
  --c-card: #fff;
  --c-card-border: #e5e5e5;
  --c-text-strong: rgba(0, 0, 0, 0.9);
  --c-text-weak: rgba(0, 0, 0, 0.65);
  --c-overlay: rgba(0, 0, 0, 0.5);
  --c-border: rgba(0, 0, 0, 0.1);
}
