.header {
  width: 100%;
  grid-area: header;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}

.header-start {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  row-gap: 6px;
}

.header-title {
  font-size: 1.4rem;
  font-style: normal;
  line-height: 21px;
  letter-spacing: 0.25px;
  text-align: left;
  margin: 0;
}

.header-validation {
  display: flex;
}

.search-page-overlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}